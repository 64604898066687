const Header = () => {
  return (
    <header
      style={{
        backgroundColor: "#7E1757",
        height: "30px",
        color: "#fff",
        display: "flex",
        alignItems: "flex-end", // Align items at the bottom
        paddingTop: "60px", // Padding for some spacing inside the header
        paddingLeft: "30px", // Padding for some spacing inside the header
        paddingBottom: "10px", // Padding for some spacing inside the header
      }}
    >
      <h1
        style={{
          fontFamily: "Fredoka One, cursive",
          fontSize: "2em",
        }}
      >
        ATO Energy
      </h1>
      <h2
        style={{
          fontStyle: "italic",
          fontSize: "1em",
          color: "rgba(255, 255, 255, 0.8)",
          paddingLeft: "20px",
        }}
      >
        - add capacity
      </h2>
    </header>
  );
};

export default Header;
