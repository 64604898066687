import React from "react";
import {
  CardGroup,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
} from "reactstrap";

function TeamCard({ data }) {
  return (
    <Card className="team-card">
      <img src={data.image} className="team-image" />
      <CardBody className="mt-3">
        <CardTitle tag="h6" style={{ color: "#7E1757", textAlign: "center" }}>
          {data.name}
        </CardTitle>
        <CardSubtitle style={{ color: "#F325A4", textAlign: "center" ,marginTop:"10px"}}>
          {data.title}
        </CardSubtitle>
        <CardText style={{ textAlign: "justify" }} className="mt-3">
          {data.desc}
        </CardText>
      </CardBody>
    </Card>
  );
}

export default TeamCard;
