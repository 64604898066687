// src/components/About.js
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  CardImg,
} from "reactstrap";
import aboutImage from "../images/aboutImage.jpg";
import Services from "./Services";
import Contact from "./Contact";
import Benefits from "./Benefits";

const About = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Stop observing after animation has triggered
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const scrollToServices = () => {
    const servicesSection = document.getElementById("services");
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="about">
      <Row>
        <Col sm="7">
          <Card body style={{ border: "none" }}>
            <CardTitle className="section-title">ABOUT</CardTitle>
            <div ref={ref} className={`title ${inView ? "slide-in" : ""}`}>
              <CardText>
                We empower the energy sector by optimizing real-time energy
                management and asset performance.
              </CardText>
              <Button outline onClick={scrollToServices}>
                Our Solutions
              </Button>
            </div>
            <p style={{ marginTop: "30px", fontSize: "20px" }}>
              Using AI, dynamic ratings, and predictive insights, we help
              businesses and grid operators enhance efficiency, ensure
              reliability, and unlock new revenue streams—building a smarter,
              more resilient energy future.
            </p>
          </Card>
        </Col>
        <Col sm="5">
          <Card body style={{ border: "none" }}>
            <CardImg
              top
              src={aboutImage}
              alt="Card image"
              className="about-image"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default About;
