// src/components/Services.js
import React, { useEffect, useRef, useState } from "react";

const Services = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Stop observing after animation has triggered
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div id="services" style={{ backgroundColor: "#f2f2f2" }}>
      <div className="service-subtitle">
        <div className="section-title">SOLUTIONS</div>
        <div ref={ref} className={`title ${inView ? "slide-in" : ""}`}>
        AI-Driven Energy Optimization for Enhanced Reliability and Efficiency
       </div>
      </div>
      <div id="services">
        <table ref={ref} className="services-table">
          <tbody>
            <tr>
              <td className="number">01</td>
              <td className={`title ${inView ? "slide-in" : ""}`}>
              Transformative Energy Solutions
              </td>
              <td className="description">At ATO Energy, we empower decision-makers in the energy sector by providing a tool that optimizes real-time energy management and asset performance. Initially designed as a base monitoring system, our solution idea has evolved to meet the dynamic needs of the energy market - helping companies ensure reliability.</td>
            </tr>
            <tr>
              <td className="number">02</td>
              <td className={`title ${inView ? "slide-in" : ""}`}>
              Optimizing Energy Operations
              </td>
              <td className="description">For businesses involved in moving energy operations, such as solar panel owners, EV charger operators, fleet managers, and wind power parks, our tool leverages dynamic ratings and AI to predict energy needs based on historical data, seasonality, and weather forecasts. This allows decision-makers to secure energy contracts that are both flexible and cost-effective - eliminating the need for expensive backup solutions while enhancing service guarantees.</td>
            </tr>
            <tr>
              <td className="number">03</td>
              <td className={`title ${inView ? "slide-in" : ""}`}>
              Enhancing Grid Management
              </td>
              <td className="description">For long-term grid owners, including DSOs, TSOs, and industrial companies, we provide dynamic ratings and AI-driven long-term capacity predictions, helping to manage grid performance and asset usage more effectively. Our digital twin technology simulates grid conditions, forecasts when and where to optimize or replace equipment, and reduces the risk of overload - ensuring reliable operations while minimizing operational costs.</td>
            </tr>
            <tr>
              <td className="number">04</td>
              <td className={`title ${inView ? "slide-in" : ""}`}>
              Strategic Solutions
              </td>
              <td className="description">In both short-term and long-term scenarios, our solution supports decision-makers with critical data, providing the insights they need to make smarter, more efficient energy decisions. Our dynamic ratings not only increase cost-efficiency but also improve service levels - helping customers stay ahead in a constantly shifting energy landscape.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Services;
