const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "#7E1757",
        padding: "10px",
        color: "#f2f2f2",
        textAlign: "center",
        position: "relative", // Fixed positioning to stick to the bottom
        bottom: "0",
        width: "100%",
      }}
    >
      <p>&copy; 2024 ATO Energy. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
