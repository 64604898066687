import React from 'react';
import ReactDOM from 'react-dom/client';  // Importing the React 18+ version of ReactDOM
import App from './App';  // Importing the main App component
import './App.css'; // Importing the global CSS file
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
// Creating a root element and rendering the App component inside the root div
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
