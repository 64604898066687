import React, { useEffect, useRef, useState } from "react";
import {
  CardGroup,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  TbHeartRateMonitor,
  TbChartHistogram,
  TbDirections,
} from "react-icons/tb";
import { IoMdTimer } from "react-icons/io";
import { FaLaptopCode } from "react-icons/fa";
import { SlEnergy } from "react-icons/sl";
import { IoIosTrendingUp, IoMdGlobe } from "react-icons/io";
import { AiFillOpenAI } from "react-icons/ai";
import benefit from "../images/benefitImage.jpg";
import { MdBatchPrediction } from "react-icons/md";
import { GiProfit } from "react-icons/gi";

const Benefits = () => {
  const [inView, setInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.disconnect(); // Stop observing after animation has triggered
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div className="benefits">
      <CardTitle className="section-title">ESSENTIAL INSIGHTS</CardTitle>

      <div ref={ref} className={`title ${inView ? "slide-in" : ""}`}>
        <CardText>
          Unlock the Power of Real-Time Data for Smarter Decisions!
        </CardText>
      </div>

      <CardGroup className="mt-4">
        <Col xs="12" md="6" lg="4">
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <FaLaptopCode size={40} style={{ color: "#F325A4" }} />
                </Col>
                <Col className="d-flex align-items-center">
                  <CardText>Software, without sensors</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <TbChartHistogram size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-left">
                  <CardText>Optimizes transformers and powerlines</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <SlEnergy size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>
                    Optimize Energy Production with real-time insights into
                    supply and demand dynamics
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <IoIosTrendingUp size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>
                    Increases capacity up to 50%, grid expansion
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <TbDirections size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>Increases service guaranties level</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <IoMdGlobe size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>
                    Market potential: in Europe there is 4 000 000 transformers
                    and 2 500 DSOs
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col xs="0" md="6" lg="4">
          <img
            src={benefit}
            alt="power asset"
            style={{ width: "100%", height: "90%", alignSelf: "center" }}
          ></img>
        </Col>
        <Col xs="12" md="6" lg="4">
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <AiFillOpenAI size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center">
                  <CardText>
                    AI, machine-learning algorithms to do forecasts
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <TbChartHistogram size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-left">
                  <CardText>Dynamic rating</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <IoMdTimer size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>Real-time monitoring</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <TbHeartRateMonitor size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>Monitors loss of life</CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <MdBatchPrediction size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>
                    Predictive analysis to identify weak points in the grid
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card style={{ borderLeft: "none", borderRight: "none" }}>
            <CardBody className="mt-2">
              <Row>
                <Col sm="2">
                  <GiProfit size={40} style={{ color: "#F325A4 " }} />
                </Col>
                <Col className="d-flex align-items-center ">
                  <CardText>
                    Unlocks New Revenue Streams with Dynamic Energy Contracts
                  </CardText>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </CardGroup>
    </div>
  );
};

export default Benefits;
